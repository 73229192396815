import React, { useCallback } from 'react';

import { I18nText } from '../../../../../../../../types';
import { IconsEnum } from '../../../../../../../../assets/icons/types';

import {
  UserFinanceRoleName,
  UserID,
  UserImageUUID,
  UserNanoID,
  UserUUID
} from '../../../../../../../users/usersTypes';

import { PureIconButtonHelper } from '../../../../../../../../helpers/buttons/PureIconButtonHelper';
import { UserAvatarLink } from '../../../../../../helpers/UserAvatarLink';
import { Checkbox } from '../../../../../../../../helpers/Checkbox';
import { Translate } from '../../../../../../../../helpers/Translate';

import { stringsKeys } from '../../../../../../../../locales/keys';

export interface CheckableUsersListItemUserType {
  id: UserID;
  uuid: UserUUID;
  nanoId: UserNanoID;
  recentUuid?: UserUUID;
  fullName: string;
  email: string;
  image: {
    uuid: UserImageUUID;
    file: string;
  };
  financeRoleName: UserFinanceRoleName;
}

interface CheckableUsersListItemProps {
  checked?: boolean;
  disabled?: boolean;
  onCheck: (user: CheckableUsersListItemUserType, checked: boolean) => void;
  onRemoveRecentUser?: ({ uuid: UserUUID }) => void;
  user: CheckableUsersListItemUserType;
  alreadySelectedI18nTitle?: I18nText;
}

function CheckableUsersListItem({
  checked,
  disabled,
  onCheck,
  onRemoveRecentUser,
  user,
  alreadySelectedI18nTitle = stringsKeys.alreadyAdded
}: CheckableUsersListItemProps) {
  const handleCheck = useCallback(
    (isChecked: boolean) => onCheck?.(user, isChecked),
    [user, onCheck]
  );

  const handleRemove = useCallback(
    () => onRemoveRecentUser?.({ uuid: user.recentUuid }),
    [onRemoveRecentUser, user.recentUuid]
  );

  return (
    <li className="group p-2 -mx-2 rounded-lg bg-gray-500 bg-opacity-0 hover:bg-opacity-10">
      <div className="flex items-center space-x-2">
        <div className="flex items-center">
          <Checkbox
            checked={checked}
            disabled={disabled}
            onChange={handleCheck}
          />
        </div>
        <div className="flex-1 flex relative items-center space-x-3 truncate">
          <div className="flex-shrink-0">
            <UserAvatarLink avatarType="main" user={user} />
          </div>
          <div className="flex-1 min-w-0">
            <p className="text-sm font-medium text-gray-900 dark:text-gray-200 truncate">
              {user?.fullName}
            </p>
            <p className="text-sm text-gray-500 dark:text-gray-400 truncate space-x-1">
              {disabled ? (
                <span className="bg-gray-200 text-gray-900 dark:bg-gray-800 dark:text-gray-100 rounded px-2 py-1 text-xs">
                  <Translate id={alreadySelectedI18nTitle} />
                </span>
              ) : null}
              <span>{user.financeRoleName}</span>
            </p>
          </div>
        </div>
        {onRemoveRecentUser ? (
          <div>
            <PureIconButtonHelper
              className="py-2 pl-2 pr-2 rounded-full inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:outline-none focus:ring-2 focus:ring-gray-900 dark:focus:ring-gray-100 hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0 opacity-0 group-hover:opacity-100 focus:opacity-100"
              blurOnMouseLeave
              icon={IconsEnum.X_OUTLINE}
              onClick={handleRemove}
            />
          </div>
        ) : null}
      </div>
    </li>
  );
}

export default CheckableUsersListItem;
