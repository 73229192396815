import React, { useCallback, useState } from 'react';
import includes from 'lodash/includes';

import { I18nText, ID, UUID } from '../../../../../../../../types';

import {
  CheckableUsersListItemUserType,
  CheckableUsersListItem
} from '../CheckableUsersListItem';
import { Checkbox } from '../../../../../../../../helpers/Checkbox';

interface CheckableUsersListProps {
  invitedUserUuids?: UUID[];
  onCheck?: (user: CheckableUsersListItemUserType, checked: boolean) => void;
  onCheckAll?: (
    users: CheckableUsersListItemUserType[],
    checked: boolean
  ) => void;
  onRemoveRecentUser?: ({ uuid: UUID }) => void;
  selectedUserIds: ID[];
  users: CheckableUsersListItemUserType[];
  alreadySelectedI18nTitle?: I18nText;
}

function CheckableUsersList({
  invitedUserUuids,
  onCheck,
  onCheckAll,
  onRemoveRecentUser,
  selectedUserIds,
  users,
  alreadySelectedI18nTitle
}: CheckableUsersListProps) {
  const [checkedAll, setCheckedAll] = useState<boolean>(false);

  const handleCheckAll = useCallback(
    (checked: boolean) => {
      onCheckAll?.(users, checked);
      setCheckedAll(checked);
    },
    [users, onCheckAll, setCheckedAll]
  );

  return (
    <ul>
      <li className="group p-2 -mx-2 rounded-lg bg-gray-500 bg-opacity-0 hover:bg-opacity-10">
        <div className="flex items-center space-x-2">
          <div className="flex items-center">
            <Checkbox checked={checkedAll} onChange={handleCheckAll} />
          </div>
        </div>
      </li>

      {users?.map((user) => (
        <CheckableUsersListItem
          checked={includes(selectedUserIds, user.id)}
          disabled={includes(invitedUserUuids, user?.uuid)}
          key={user.uuid}
          onCheck={onCheck}
          onRemoveRecentUser={onRemoveRecentUser}
          user={user}
          alreadySelectedI18nTitle={alreadySelectedI18nTitle}
        />
      ))}
    </ul>
  );
}

export default CheckableUsersList;
