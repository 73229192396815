import React, { Fragment } from 'react';
import Select from 'react-select';
import cl from 'classnames';
import debounce from 'lodash/debounce';

import {
  MultiSelectChangeCallbackType,
  MultiSelectMixedDataType
} from './types';
import {
  MultiSelectProps,
  MultiSelectOptionalProps,
  MultiSelectValueType
} from './MultiSelect.types';

import { useMultiSelect } from './hooks/useMultiSelect';
import { useTranslate } from '../../common/hooks/useTranslate';

import { Translate } from '../Translate';
import { DropdownIndicator } from './components/DropdownIndicator';

interface MultiSelectRequiredProps {
  onChange?: MultiSelectChangeCallbackType;
  value?: MultiSelectValueType;
}

function MultiSelect({
  classNamePrefix = null,
  closeMenuOnSelect = true,
  data = [],
  defaultValue = null,
  disabled = false,
  emptyValue = null,
  error,
  errorClassName,
  i18nLabel = null,
  i18nPlaceholder,
  inputWrapperClassName = null,
  isClearable = false,
  isSearchable = false,
  labelClassName,
  menuIsOpen,
  menuPlacement = 'auto',
  menuPosition,
  multi = false,
  name,
  onChange,
  onInputChange,
  optionsLoading = false,
  placeholder = null,
  showError = true,
  value = null
}: MultiSelectProps & MultiSelectOptionalProps & MultiSelectRequiredProps) {
  const { t } = useTranslate();

  const {
    dataWithEmptyValue,
    handleFilterOption,
    handleKeyDown,
    handleMenuClose,
    handleMenuOpen,
    handleOptionsLoadingMessage,
    localizeValue
  } = useMultiSelect({
    data,
    emptyValue,
    optionsLoading
  });

  const inputView = (
    <Select<
      MultiSelectMixedDataType | MultiSelectMixedDataType[] | null,
      boolean
    >
      classNamePrefix={classNamePrefix}
      closeMenuOnSelect={closeMenuOnSelect}
      defaultValue={localizeValue(defaultValue)}
      filterOption={onInputChange ? handleFilterOption : undefined}
      components={{ DropdownIndicator }}
      // hideSelectedOptions={false}
      isClearable={isClearable}
      isDisabled={disabled}
      isMulti={multi}
      isSearchable={isSearchable}
      menuIsOpen={menuIsOpen}
      menuPlacement={menuPlacement}
      menuPosition={menuPosition}
      name={name}
      noOptionsMessage={handleOptionsLoadingMessage}
      onChange={onChange}
      onInputChange={onInputChange ? debounce(onInputChange, 500) : undefined}
      onKeyDown={handleKeyDown}
      onMenuClose={handleMenuClose}
      onMenuOpen={handleMenuOpen}
      options={dataWithEmptyValue}
      placeholder={i18nPlaceholder ? t(i18nPlaceholder) : placeholder}
      value={value}
    />
  );

  if (inputWrapperClassName) {
    return (
      <Fragment>
        {i18nLabel ? (
          <label htmlFor={name} className={labelClassName}>
            <Translate id={i18nLabel} />
          </label>
        ) : null}
        <div className={inputWrapperClassName}>
          {inputView}
          {error && showError && (
            <p className={cl(errorClassName || 'mt-2 text-sm text-red-600')}>
              {/^forms\.errors+/.test(error) ? <Translate id={error} /> : error}
            </p>
          )}
        </div>
      </Fragment>
    );
  }

  return inputView;
}

export default MultiSelect;
